import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box, Stack } from '@mui/material';
import ObjectOutlineIcon from 'modules/theme/components/icons/category/ObjectOutline.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IBranchName {
    branchName: string;
}

export const BranchName: FC<IBranchName> = ({
    branchName,
}) => {

    return (
        <Stack direction='row' gap={1} data-test-id='branchName'>
            <Box>
                <Svg icon component={ObjectOutlineIcon} />
            </Box>
            <Text variant='body1' semibold noWrap>{branchName}</Text>
        </Stack>
    );
};
