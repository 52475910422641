import { useCallback, useState, useEffect } from 'react';
import { realEstateStatsUserApi } from 'modules/api/client';

interface IReturn {
    isFetchingByTransaction: boolean;
    chartDataByTransactionItems: number[];
    transactionChartLabels: string[];
    realEstatesTotalCount: number;
}

export const usePortfolioByTransaction = (agencyId: string): IReturn => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [chartDataByTransactionItems, setChartDataByTransactionItems] = useState<number[]>([]);
    const [transactionChartLabels, setTransactionChartLabels] = useState<string[]>([]);
    const [realEstatesTotalCount, setRealEstatesTotalCount] = useState<number>(0);

    const fetchPortfolioStatsByTransactionApiCall = useCallback(async () => {
        try {
            setIsFetching(true);
            const { data } = await realEstateStatsUserApi.getUserPortfolioByTransaction(agencyId);

            const items = data.items;
            const itemsData = items.map((item) => item.percent);
            const labels = items.map((item) => item.name);

            setChartDataByTransactionItems(itemsData);
            setRealEstatesTotalCount(data.totalCount);
            setTransactionChartLabels(labels);

        } catch (error) {
        } finally {
            setIsFetching(false);
        }
    }, [agencyId]);

    useEffect(() => {
        (async () => {
            await fetchPortfolioStatsByTransactionApiCall();
        })();
    }, [fetchPortfolioStatsByTransactionApiCall]);

    return {
        isFetchingByTransaction: isFetching,
        chartDataByTransactionItems,
        transactionChartLabels,
        realEstatesTotalCount
    };
};