import React, { FC } from 'react';
import { Box, Drawer, Grid, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import { IAddress } from 'modules/realEstate/common/interfaces/IAddress';
import {
    getAgencyAddressMobileFormatted
} from 'modules/theme/components/agency/agency/detailHeadline/helpers/mobile/getAgencyAddressMobileFormatted';
import {
    useStepDrawerMobile
} from 'components/page/realEstate/agency/detail/hooks/headline/mobile/useStepDrawerMobile';
import {
    StepsWrapper
} from 'components/page/realEstate/common/drawer/StepsWrapper';
import {
    ILocationGps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';

interface IContactsMobileProps {
    agencyId: string;
    agencyName: string;
    agencyAddress: IAddress;
    locationGps: ILocationGps;
    web?: string;
    messenger?: string;
    whatsapp?: string;
    openHours?: IOpenHours;
    parentAgencyName?: string;
}
export const ContactsMobile: FC<IContactsMobileProps> = ({
    agencyId,
    agencyName,
    agencyAddress,
    locationGps,
    web,
    messenger,
    whatsapp,
    openHours,
    parentAgencyName,
}) => {
    const { palette } = useTheme();
    const {
        isOpen: isDrawerOpen,
        stepDrawerContent,
        openDrawer,
        closeDrawer,
    } = useStepDrawerMobile(agencyId, agencyName, agencyAddress, locationGps, openHours, web, whatsapp, messenger, parentAgencyName);
    const rootRef = React.useRef<HTMLDivElement>(null);

    return <>
        <Box
            ref={rootRef}
            position='fixed'
            sx={{
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
            }}
            boxShadow='0px -2px 8px 0px rgba(0, 0, 0, 0.08)'
            bgcolor={palette.background.default}
            left={0}
            right={0}
            bottom={0}
            p={2}
            zIndex={2}
        >
            <Grid container columns={13} spacing={1}>
                <Grid item xs={7}>
                    <Text variant='body2' semibold noWrap>
                        {agencyName}
                    </Text>
                    <Text variant='label2'>
                        {getAgencyAddressMobileFormatted(agencyAddress)}
                    </Text>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        text='Kontaktné údaje'
                        fullWidth
                        onClick={openDrawer}
                    />
                </Grid>
            </Grid>
        </Box>
        <Drawer
            container={() => rootRef.current}
            anchor='bottom'
            open={isDrawerOpen}
            onClose={closeDrawer}
        >
            <StepsWrapper>
                {stepDrawerContent}
            </StepsWrapper>
        </Drawer>
    </>;
};
