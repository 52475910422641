import { useCallback, useState, useEffect } from 'react';
import { realEstateStatsUserApi } from 'modules/api/client';

interface IReturn {
    isFetchingByCategory: boolean;
    chartDataByCategoryItems: number[];
    categoryChartLabels: string[];
}

export const usePortfolioByCategory = (agencyId: string): IReturn => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [chartDataByCategoryItems, setChartDataByCategoryItems] = useState<number[]>([]);
    const [categoryChartLabels, setCategoryChartLabels] = useState<string[]>([]);

    const fetchPortfolioStatsByCategoryApiCall = useCallback(async () => {
        try {
            setIsFetching(true);
            const { data } = await realEstateStatsUserApi.getUserPortfolioByCategory(agencyId);

            const items = data.items;
            const itemsData = items.map((item) => item.count);
            const labels = items.map((item) => item.name);

            setChartDataByCategoryItems(itemsData);
            setCategoryChartLabels(labels);
        } catch (error) {
        } finally {
            setIsFetching(false);
        }
    }, [agencyId]);

    useEffect(() => {
        (async () => {
            await fetchPortfolioStatsByCategoryApiCall();
        })();
    }, [fetchPortfolioStatsByCategoryApiCall]);

    return {
        isFetchingByCategory: isFetching,
        chartDataByCategoryItems,
        categoryChartLabels
    };
};