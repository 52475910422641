import { FC } from 'react';
import { Box } from '@mui/material';
import { useAgents } from 'components/page/realEstate/agency/detail/hooks/agents/useAgents';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import { ILemmatizeWordAccordingToNumber } from 'modules/lemmatize/ILemmatizeWordAccordingToNumber';
import { Subtitle } from 'components/page/realEstate/agency/detail/components/tabs/content/common/subtitle/Subtitle';
import {
    AgentsList
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/agents/partials/list/AgentsList';
import {
    ShowMoreBtn
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/common/showMoreBtn/ShowMoreBtn';
import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
interface IAgents {
    parentId: string;
    firstAgents: IAgent[];
    numberOfTotalAgents: number;
}

export const Agents: FC<IAgents> = ({ parentId, firstAgents, numberOfTotalAgents }) => {
    const {
        agents,
        isFetching,
        fetchMoreAgents,
        nextFetchAgentsCount,
    } = useAgents(parentId, numberOfTotalAgents, firstAgents);

    const numberOfTotalCurrectAgents= agents.length;
    const hasAgents = numberOfTotalCurrectAgents > 0;
    const showMoreBtn = numberOfTotalCurrectAgents < numberOfTotalAgents;

    const lemmatizeWords: ILemmatizeWordAccordingToNumber = {
        eqZero: 'bez maklérov',
        eqOne: 'maklér',
        betweenTwoAndFourInclusive: 'makléri',
        moreThenFour: 'maklérov',
        withoutNumberIfZero: true
    };

    const totalAgentsString = lemmatizeWordAccordingToNumber(numberOfTotalAgents, lemmatizeWords);

    return <>
        {hasAgents &&
            <>
                <Box mb={3}>
                    <Subtitle subtitle='Makléri' helpText={hasAgents && `(${totalAgentsString})`}/>
                </Box>

                <Box mb={{ xs: 3, md: 5 }}>
                    <AgentsList
                        agents={agents}
                        isFetching={isFetching}
                        nextFetchAgentsCount={nextFetchAgentsCount}/>
                </Box>

                {showMoreBtn &&
                    <Box>
                        <ShowMoreBtn
                            center
                            isDisabled={isFetching}
                            onClick={fetchMoreAgents}
                            text='Zobraziť ďalších maklérov'
                        />
                    </Box>
                }
            </>
        }
    </>;
};
