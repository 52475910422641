import { FC } from 'react';
import { Box } from '@mui/material';
import { Subtitle } from 'components/page/realEstate/agency/detail/components/tabs/content/common/subtitle/Subtitle';
import { usePortfolioByCategory } from 'components/page/realEstate/agency/detail/hooks/portfolio/usePortfolioByCategory';
import { usePortfolioByTransaction } from 'components/page/realEstate/agency/detail/hooks/portfolio/usePortfolioByTransaction';
import {
    PortfolioCharts
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/portfolio/partials/chart/PortfolioCharts';

interface IPortfolio {
    agencyId: string;
}

export const Portfolio: FC<IPortfolio> = ({ agencyId }) => {
    const {
        chartDataByCategoryItems,
        categoryChartLabels
    } = usePortfolioByCategory(agencyId);
    const {
        chartDataByTransactionItems,
        transactionChartLabels,
        realEstatesTotalCount
    } = usePortfolioByTransaction(agencyId);

    return <>
        {
            !!realEstatesTotalCount &&
                <Box mb={3}>
                    <Box mb={2}>
                        <Subtitle subtitle='Portfólio nehnuteľností'/>
                    </Box>

                    <PortfolioCharts
                        realEstatesTotalCount={realEstatesTotalCount}
                        chartDataByCategoryItems={chartDataByCategoryItems}
                        chartDataByTransactionItems={chartDataByTransactionItems}
                        transactionChartLabels={transactionChartLabels}
                        categoryChartLabels={categoryChartLabels}
                    />
                </Box>
        }
    </>;
};
