import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { AgencyAddressMobile } from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/AgencyAddressMobile';
import { AgencyDetailMobileMap } from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/AgencyDetailMobileMap';
import { ContactBadges } from 'components/page/realEstate/common/drawer/partials/ContactBadges';
import { PhoneEmailButtons } from 'components/page/realEstate/common/drawer/partials/PhoneEmailButtons';
import { IStepProps } from 'components/page/realEstate/common/interfaces/IStepProps';
import { IAddress } from 'modules/realEstate/common/interfaces/IAddress';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import {
    ILocationGps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';

interface IProps extends IStepProps {
    agencyName: string;
    agencyAddress: IAddress;
    locationGps: ILocationGps;
    openHours?: IOpenHours;
    web?: string;
    messenger?: string;
    whatsapp?: string;
    onPhoneButtonClick: () => void;
    onEmailButtonClick: () => void;
    isFetchingPhoneNumber: boolean;
    onWebClick?: () => void;
    onMessengerClick?: () => void;
    onWhatsappClick?: () => void;
}

export const StepInitialScreen: FC<IProps> = ({
    onIconClick,
    agencyName,
    agencyAddress,
    locationGps,
    openHours,
    web,
    messenger,
    whatsapp,
    onPhoneButtonClick,
    onEmailButtonClick,
    isFetchingPhoneNumber,
    onWhatsappClick,
    onMessengerClick,
    onWebClick,
}) => {
    return <>
        <Grid item xs={12}>
            <Svg icon
                component={CloseIcon}
                onIconClick={() => {
                    onIconClick();
                }}
            />
        </Grid>

        <Grid item xs={12}>
            <AgencyAddressMobile agencyName={agencyName} agencyAddress={agencyAddress}/>
        </Grid>

        {(locationGps) && (
            <Grid item xs={12}>
                <AgencyDetailMobileMap
                    locationGps={locationGps}
                    openHours={openHours}
                />
            </Grid>
        )}

        {(web || messenger || whatsapp) && (
            <Grid item xs={12}>
                <ContactBadges
                    web={web}
                    messenger={messenger}
                    whatsapp={whatsapp}
                    onMessengerClick={onMessengerClick}
                    onWhatsappClick={onWhatsappClick}
                    onWebClick={onWebClick}
                />
            </Grid>
        )}
        <Grid item xs={12}>
            <PhoneEmailButtons
                isFetchingPhoneNumber={isFetchingPhoneNumber}
                onPhoneButtonClick={onPhoneButtonClick}
                onEmailButtonClick={onEmailButtonClick}
            />
        </Grid>
    </>;
};
