import { FC, ReactNode } from 'react';
import { CardActionArea, Box, useTheme } from '@mui/material';

interface ICardWrapper {
    children: ReactNode;
}

export const CardWrapper: FC<ICardWrapper> = ({ children }) => {
    const { palette, spacing } = useTheme();

    return <CardActionArea sx={{ borderRadius: 4, width: '100%' }}>
        <Box
            borderRadius='inherit'
            height={{ xs: 192, md: 292 }}
            border={`1px solid ${palette.labelTertiary.main}`}
            boxShadow={'0px 2px 4px ' + palette.labelQuarternary.main}
            padding={{ xs: spacing(3, 2), md: spacing(5, 4, 6) }}
        >
            {children}
        </Box>
    </CardActionArea>;
};
