import { FC } from 'react';
import { IContacts } from 'modules/realEstate/common/interfaces/IContacts';
import { Grid } from '@mui/material';
import { IAddress } from 'modules/realEstate/common/interfaces/IAddress';
import { AgencyLogoMobile } from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/AgencyLogoMobile';
import { ProfiBadge } from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/ProfiBadge';
import { ContactsMobile } from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/ContactsMobile';
import { AgencyAddressMobile } from 'components/page/realEstate/agency/detail/components/partials/headline/mobile/partials/AgencyAddressMobile';
import {
    ILocationGps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';

interface IProps {
    isProfi: boolean;
    agencyId: string;
    agencyName: string;
    agencyLogoUrl: string;
    agencyAddress: IAddress;
    locationGps: ILocationGps;
    contacts: IContacts;
    openHours?: IOpenHours;
    branchType: ERouterBranchType;
    parentAgencyName?: string;
}

export const HeadlineMobile: FC<IProps> = ({
    isProfi,
    agencyId,
    agencyName,
    agencyLogoUrl ,
    agencyAddress,
    locationGps,
    contacts,
    openHours,
    branchType,
    parentAgencyName,
}) => {
    const alt = [...agencyName.split(' '), 'logo', branchType === ERouterBranchType.BRANCH ? 'pobocky' : undefined, 'realitna', 'kancelaria']
        .filter(value => !!value).join('_');

    return <>
        <Grid container alignItems='center'>
            <Grid container item height='100%' alignItems='center' justifyContent='space-between' mb={3}>
                <Grid item>
                    <AgencyAddressMobile agencyName={agencyName} agencyAddress={agencyAddress} />
                </Grid>
                <Grid item>
                    <AgencyLogoMobile imgUrl={agencyLogoUrl} alt={alt} title={alt} />
                </Grid>
            </Grid>
            <Grid container item>
                {isProfi && <Grid item>
                    <ProfiBadge/>
                </Grid>}
            </Grid>
        </Grid>
        <ContactsMobile
            agencyId={agencyId}
            agencyName={agencyName}
            agencyAddress={agencyAddress}
            locationGps={locationGps}
            web={contacts.web}
            messenger={contacts.messenger}
            whatsapp={contacts.whatsapp}
            openHours={openHours}
            parentAgencyName={parentAgencyName}
        />
    </>;
};
