import { FC } from 'react';
import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Subtitle } from 'components/page/realEstate/agency/detail/components/tabs/content/common/subtitle/Subtitle';
import { CountInfo } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/partials/CountInfo/CountInfo';
import { Description } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/partials/Description';
import { IDetailAdvertisementsCountInfo } from 'modules/realEstate/common/interfaces/Detail/IDetailAdvertisementsCountInfo';

interface IAbout {
    description: string;
    advertisementsCountsInfo: IDetailAdvertisementsCountInfo;
}

export const About: FC<IAbout> = ({ description, advertisementsCountsInfo }) => {
    const isDescriptionExists = description && !!description;
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const fullScreenVersion = !isMobile && !isDescriptionExists;

    return <Box pb={fullScreenVersion && 2}>
        <Box mb={3}>
            <Subtitle subtitle='O firme'/>
        </Box>

        <Grid
            container
            spacing={1}
            justifyContent={isDescriptionExists ? 'space-between' : 'flex-end'}
            flexDirection={{ xs: 'column', sm: 'row' }}
        >
            {isDescriptionExists &&
                <Grid item md={6} sm={12}>
                    <Description description={description}/>
                </Grid>
            }

            <Grid item md={isDescriptionExists ? 5 : 12} sm={12}>
                <CountInfo
                    countInfo={advertisementsCountsInfo}
                    fullScreenVersion={fullScreenVersion}
                />
            </Grid>
        </Grid>
    </Box>;
};
