import { FC } from 'react';
import { Box } from '@mui/material';
import {
    IOpenHours as IOpenHoursFromProps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import { transformOpenHours } from 'components/page/realEstate/agency/detail/helpers/transformOpenHours';
import {
    OpenHoursDay
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/whereWeAre/partials/openHours/partials/OpenHoursDay';

interface IOpenHours {
    openHours: IOpenHoursFromProps;
}
export const OpenHours: FC<IOpenHours> = ({ openHours }) => {
    const openHoursDays = transformOpenHours(openHours);

    return <Box
        padding={4}
        height='100%'
        display='flex'
        borderRadius={3}
        justifyContent='center'
        flexDirection='column'
        border={(theme) => `1px solid ${theme.palette.backgroundTertiary.main}`}
    >
        {openHoursDays.map((openHoursDay) =>
            <OpenHoursDay
                key={openHoursDay.day}
                openHoursDay={openHoursDay}
            />
        )}
    </Box>;
};