import React, { FC } from 'react';
import { LocationMap } from 'components/page/realEstate/agency/detail/components/partials/locationMap/LocationMap';
import {
    ILocationGps
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import {
    IOpenHours
} from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import { useOpenHours } from 'components/page/realEstate/agency/detail/hooks/headline/mobile/useOpenHours';

interface IProps {
    locationGps: ILocationGps;
    openHours: IOpenHours;
}

export const AgencyDetailMobileMap: FC<IProps> = ({
    locationGps,
    openHours
}) => {
    const {
        text,
        isOpen,
    } = useOpenHours(openHours);

    return <LocationMap
        height={154}
        locationGps={locationGps}
        wrapperBorderRadius={2}
        openHoursNote={isOpen ? text : undefined}
    />;
};
