import { FC } from 'react';
import { Grid, useTheme } from '@mui/material';
import {
    CategoryChart
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/portfolio/partials/chart/partials/CategoryChart';
import {
    TransactionChart
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/portfolio/partials/chart/partials/TransactionChart';
import {
    initializePieChartConfig,
    initializeBarChartConfig
} from 'components/page/realEstate/agency/detail/helpers/agencyDetailPortfolioChartsHelper';

interface IPortfolioCharts {
    realEstatesTotalCount: number;
    categoryChartLabels: string[];
    transactionChartLabels: string[];
    chartDataByCategoryItems: number[];
    chartDataByTransactionItems: number[];
}

export const PortfolioCharts: FC<IPortfolioCharts> = ({
    categoryChartLabels,
    realEstatesTotalCount,
    transactionChartLabels,
    chartDataByCategoryItems,
    chartDataByTransactionItems,
}) => {
    const theme = useTheme();

    return <Grid container spacing={{ xs: 1, md: 2 }}>

        <Grid item xs={12} sm={6}>
            <CategoryChart
                height={300}
                data={chartDataByCategoryItems}
                options={initializeBarChartConfig(
                    chartDataByCategoryItems,
                    categoryChartLabels,
                    theme
                )}
            />
        </Grid>

        <Grid item xs={12} sm={6}>
            <TransactionChart
                height={320}
                data={chartDataByTransactionItems}
                options={initializePieChartConfig(
                    chartDataByTransactionItems,
                    transactionChartLabels,
                    realEstatesTotalCount,
                    'nehnuteľností',
                    theme
                )}
            />
        </Grid>
    </Grid>;
};
