import { FC } from 'react';
import dynamic from 'next/dynamic';
import { ApexOptions } from 'apexcharts';
import {
    ChartWrapper
} from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/portfolio/partials/chart/wrapper/ChartWrapper';

const ApexChart = dynamic(() => import('react-apexcharts'), { ssr: false });

interface ICategoryChart {
    data: number[];
    height: number;
    options: ApexOptions;
}

export const CategoryChart: FC<ICategoryChart> = ({
    data,
    height,
    options
}) => {

    return <ChartWrapper>
        <ApexChart
            series={[{ data }]}
            options={options}
            height={height}
            type='bar'
        />
    </ChartWrapper>;
};