import { FC } from 'react';
import { TabContext } from '@mui/lab';
import { useRouter } from 'next/navigation';
import { Box, Container, Theme } from '@mui/material';
import { TabMenu } from 'components/page/realEstate/agency/detail/components/tabs/menu/TabMenu';
import { IAgencyDetail } from 'modules/realEstate/agency/detail/interfaces/agency/IAgencyDetail';
import { Content } from 'components/page/realEstate/agency/detail/components/tabs/content/Content';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { getBranchType } from 'components/page/realEstate/common/helpers/AgencyHelper';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { IBranch } from 'modules/realEstate/agency/detail/interfaces/IBranch';
import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
import Link from 'components/link/Link';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
interface ITabs {
    agency: IAgencyDetail;
    currentTab: ERouterTabs;
    numberOfTotalBranches: number;
    firstBranches: IBranch[];
    firstAgents: IAgent[];
    numberOfTotalAgents: number;
    firstAdvertisements: IAdvertisement[];
    preloadedPage: number;
}

export const Tabs: FC<ITabs> = ({ agency, currentTab, firstBranches, numberOfTotalBranches, firstAgents, numberOfTotalAgents, firstAdvertisements, preloadedPage }) => {
    const router = useRouter();
    const {
        agencyId,
        isCentralBranch,
        agencySlugName,
        openHours,
        description,
        locationGps,
        parentAgency,
        advertisementsCountsInfo,
    } = agency;

    return <TabContext value={currentTab}>
        <Box visibility='hidden'>
            <Link href={REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, getBranchType(isCentralBranch), ERouterTabs.ABOUT)}>about</Link>
            <Link href={REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, getBranchType(isCentralBranch), ERouterTabs.ADVERTISEMENT)}>advertisements</Link>
        </Box>
        <Box
            borderTop={(theme: Theme) => `1px solid ${theme.palette.dividerTransparent}`}
            borderBottom={(theme: Theme) => `1px solid ${theme.palette.dividerTransparent}`}
            mb={{ xs: 12, md: 8 }}
        >
            <Container sx={{ paddingBottom: 0 }}>
                <TabMenu
                    numberOfTotalAdvertisements={advertisementsCountsInfo.totalCount}
                    onTabChange={(newTab: ERouterTabs) => {
                        router.push(REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, getBranchType(isCentralBranch), newTab));
                    }}
                />
            </Container>
        </Box>

        <Container disableGutters>
            <Content
                agencyId={agencyId}
                openHours={openHours}
                description={description}
                locationGps={locationGps}
                advertisementsCountsInfo={advertisementsCountsInfo}
                firstBranches={firstBranches}
                numberOfTotalBranches={numberOfTotalBranches}
                firstAgents={firstAgents}
                numberOfTotalAgents={numberOfTotalAgents}
                firstAdvertisements={firstAdvertisements}
                preloadedPage={preloadedPage}
                parentAgency={parentAgency}
                agencySlugName={agencySlugName}
                branchType={getBranchType(isCentralBranch)}
            />
        </Container>
    </TabContext>;
};
