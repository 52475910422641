import { FC } from 'react';
import { TabList } from '@mui/lab';
import { Tab, useTheme } from '@mui/material';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';

interface ITabMenu {
    onTabChange: (newTab: ERouterTabs) => void
    numberOfTotalAdvertisements: number,
}

export const TabMenu: FC<ITabMenu> = ({ onTabChange, numberOfTotalAdvertisements }) => {
    const { palette } = useTheme();

    return <>
        <TabList
            variant='scrollable'
            onChange={(e, val) => onTabChange(val)}
            TabIndicatorProps={{ style: { backgroundColor: palette.labelPrimary.main, height: 3, borderRadius: 3 }}}
        >
            <Tab
                label='O firme'
                value={ERouterTabs.ABOUT}
                sx={{ fontWeight: { xs: 900, md: 600 }}}
            />

            <Tab
                value={ERouterTabs.ADVERTISEMENT}
                label={`Aktívne ponuky (${numberOfTotalAdvertisements})`}
                sx={{ fontWeight: { xs: 900, md: 600 }}}
            />
        </TabList>
    </>;
};
