import { ApexOptions } from 'apexcharts';
import { Theme } from '@mui/material';

export const initializeBarChartConfig = (
    data: number[],
    labels: string[],
    theme: Theme
): ApexOptions => ({
    chart: {
        type: 'bar',
        background: 'transparent',
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    tooltip: {
        enabled: false
    },
    plotOptions: {
        bar: {
            distributed: true,
            borderRadius: 4,
            columnWidth: '30%',
        }
    },
    colors: [
        theme.palette.primary.main,
        '#F5793C',
        '#FFC934',
        '#B3B662',
        '#65A291',
        '#0088CC'
    ],
    dataLabels: {
        enabled: false
    },
    legend: {
        markers: {
            radius: 16
        },
        labels: {
            colors: theme.palette.labelPrimary.main

        },
        formatter: function(seriesName, opts) {
            return [seriesName, `(${opts.w.globals.series[0][opts.seriesIndex]})`].join(' ');
        }
    },
    yaxis: {
        opposite: true,
        labels: {
            style: {
                fontSize: '10px',
                colors: theme.palette.labelSecondary.main
            }
        }
    },
    xaxis: {
        labels: {
            show: false
        }
    },
    stroke: {
        width: 0,
        curve: 'smooth',
    },
    labels,
    series: [{ data }],
    grid: {
        position: 'back',
    }
});

export const initializePieChartConfig = (
    data: number[],
    labels: string[],
    totalCount: number,
    circleLabel: string,
    theme: Theme
): ApexOptions => ({
    chart: {
        type: 'donut',
        background: 'transparent',
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    tooltip: {
        enabled: false
    },
    plotOptions: {
        pie: {
            donut: {
                size: '70%',
                labels: {
                    show: true,
                    name: {
                        show: true,
                        color: theme.palette.labelPrimary.main,
                        fontSize: '40px',
                        fontWeight: 600,
                        offsetY: 3,
                        formatter: () => totalCount.toString()
                    },
                    value: {
                        show: true,
                        fontSize: '12px',
                        color: theme.palette.labelPrimary.main,
                        fontWeight: 400,
                        formatter: () => circleLabel
                    },
                    total: {
                        show: true,
                        fontWeight: 600,
                        color: theme.palette.labelPrimary.main,
                        fontSize: '40px',
                        label: circleLabel,
                        formatter: () => circleLabel
                    }
                }
            }
        }
    },
    colors: [
        theme.palette.primary.main,
        '#F5793C',
        '#FFC934',
        '#B3B662',
        '#65A291',
        '#0088CC'
    ],
    dataLabels: {
        enabled: false
    },
    legend: {
        position: 'bottom',
        markers: {
            radius: 16
        },
        labels: {
            colors: theme.palette.labelPrimary.main

        },
        formatter: function(seriesName, opts) {
            return [seriesName, `(${opts.w.globals.series[opts.seriesIndex]}%)`].join(' ');
        }
    },
    yaxis: {
        opposite: true,
        labels: {
            style: {
                fontSize: '10px',
                colors: theme.palette.labelSecondary.main
            }
        }
    },
    xaxis: {
        labels: {
            show: false
        }
    },
    stroke: {
        width: 0,
        curve: 'smooth',
    },
    labels,
    series: [{ data },
    ],
    grid: {
        position: 'back',
    }
});