import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import Map from 'components/map/MapWithoutSSR';
import { ILocationGps } from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import { OpenHoursNote } from 'components/page/realEstate/agency/detail/components/partials/locationMap/partials/OpenHoursNote';
import realEstateLocationPin from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/whereWeAre/partials/svg/realEstateLocationPin.svg?url';

interface ILocationMap {
    height: number;
    openHoursNote?: string;
    locationGps: ILocationGps;
    wrapperBorderRadius?: number;
}

export const LocationMap: FC<ILocationMap> = ({ height, locationGps, openHoursNote, wrapperBorderRadius }) => {

    const leafletGps = useMemo(() => ({
        latitude: locationGps.lat,
        longitude: locationGps.lon
    }), [locationGps]);

    return <Box
        height={height}
        sx={{
            position: 'relative',
            '& .leaflet-container': {
                borderRadius: wrapperBorderRadius || 5
            }
        }}
    >
        {openHoursNote && <OpenHoursNote note={openHoursNote}/>}
        <Map
            markers={[{
                icon: {
                    url: realEstateLocationPin.src,
                    size: {
                        width: 80,
                        height: 80,
                    }
                },
                location: leafletGps,
            }]}
            zoomControl={false}
            center={leafletGps}
        />
    </Box>;
};
