import { FC } from 'react';
import { Box } from '@mui/system';
import { IAdvertisement } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import { LIMIT } from 'components/page/realEstate/common/advertisements/constants/limit';
import { useRouter } from 'next/navigation';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import useLoading from 'modules/stores/loading/useLoading';
import { SearchResult } from 'components/page/realEstate/common/advertisements/searchResult/SearchResult';
import { AdditionalServices } from 'components/page/realEstate/common/additionalServices/components/AdditionalServices';

interface IAdvertisements {
    agencyId: string;
    branchType: string;
    agencySlugName: string;
    firstAdvertisements: IAdvertisement[];
    numberOfTotalAdvertisements: number;
    preloadedPage: number;
}

export const Advertisements: FC<IAdvertisements> = ({
    agencyId,
    branchType,
    agencySlugName,
    firstAdvertisements,
    numberOfTotalAdvertisements,
    preloadedPage
}) => {
    const router = useRouter();

    const { start: startLoading } = useLoading();

    const totalPages = Math.ceil(numberOfTotalAdvertisements / LIMIT);

    const fetchNext = (page: number) => {
        startLoading();
        router.push(REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, branchType as ERouterBranchType, ERouterTabs.ADVERTISEMENT, page));
    };

    return (
        <>
            <SearchResult
                noData={false}
                isLoading={false}
                itemId={agencyId}
                branchType={branchType}
                totalPages={totalPages}
                onPageChange={fetchNext}
                currentPage={preloadedPage}
                itemSlugName={agencySlugName}
                advertisements={firstAdvertisements}
            />
            <Box mb={8}>
                <AdditionalServices />
            </Box>
        </>
    );
};
