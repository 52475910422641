import { IAddress } from 'modules/realEstate/common/interfaces/IAddress';

export const formatAddressLines = (address: IAddress): string[] => {
    const addressArray = [];
    let firstAddressLine = '';
    if (address.street) {
        firstAddressLine = address.street;
    }
    if (address.streetNumber) {
        firstAddressLine = `${firstAddressLine} ${address.streetNumber}`;
    }

    addressArray.push(firstAddressLine);
    if (address.city) {
        addressArray.push(address.city);
    }

    return addressArray;
};
