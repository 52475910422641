import { useCallback, useState } from 'react';
import { realEstateAgencyDetailApi } from 'modules/api/client';
import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
import { mapAgencyDetailAgentDtoToAgentInterfaceMapper } from 'modules/realEstate/agency/detail/mappers/agencyDetailAgentDtoToAgentInterfaceMapper';
import { LimitAgents } from 'modules/realEstate/agency/detail/constants/constants';

interface IReturn {
    agents: IAgent[];
    isFetching: boolean;
    fetchMoreAgents: () => void;
    nextFetchAgentsCount: number;
}

export const useAgents = (parentId: string, numberOfTotalAgents: number, firstAgents: IAgent[]): IReturn => {
    const [agents, setAgents] = useState<IAgent[]>(firstAgents);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const numberOfTotalCurrentAgents = agents.length > 0 ? agents.length : undefined;

    const nextFetchAgentsCount =
        (numberOfTotalAgents - numberOfTotalCurrentAgents) < LimitAgents
            ? numberOfTotalAgents - numberOfTotalCurrentAgents
            : LimitAgents;

    const fetchAgentsApiCall = useCallback(async (skip?: number) => {
        try {
            setIsFetching(true);
            const { data } = await realEstateAgencyDetailApi.getRealEstateAgencyAgentsByParentId(parentId, skip, LimitAgents);
            const { agents: fetchedAgents } = data;

            const newAgents = mapAgencyDetailAgentDtoToAgentInterfaceMapper(fetchedAgents);

            setAgents(current => [...current, ...newAgents]);
        } catch (error) {
        } finally {
            setIsFetching(false);
        }
    }, [parentId]);

    const fetchAgents = useCallback(() => {
        const canFetchMoreAgents = (numberOfTotalAgents > numberOfTotalCurrentAgents) && !isFetching;
        if (canFetchMoreAgents) {
            fetchAgentsApiCall(numberOfTotalCurrentAgents);
        }

    }, [fetchAgentsApiCall, numberOfTotalAgents, isFetching, numberOfTotalCurrentAgents]);

    return {
        agents,
        isFetching,
        nextFetchAgentsCount,
        fetchMoreAgents: fetchAgents,
    };
};
