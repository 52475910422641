import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { useDescriptionCollapse } from 'components/page/realEstate/agency/detail/hooks/about/useDescriptionCollapse';

interface IDescription {
    description: string;
}

export const Description: FC<IDescription> = ({ description }) => {
    const { isButtonVisible, text, collapseText } = useDescriptionCollapse(description);

    return <Box>
        <Text variant='body2' mb={2} preWrap>
            {text}
        </Text>

        {isButtonVisible &&
            <Box
                onClick={collapseText}
                sx={{
                    cursor: 'pointer'
                }}
            >
                <Text variant='body2' underLine>
                    Zobraziť viac
                </Text>
            </Box>
        }
    </Box>;
};