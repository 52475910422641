import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';


interface IShowMoreBtn {
    text: string,
    center?: boolean,
    isDisabled: boolean,
    onClick: () => void,
}

export const ShowMoreBtn: FC<IShowMoreBtn> = ({ text, center, onClick, isDisabled }) => {
    return <Box display='flex' justifyContent={center && 'center'}>
        <Box onClick={() => !isDisabled && onClick()} sx={{ cursor: !isDisabled && 'pointer' }} display='inline-block'>
            <Text
                underLine
                center={center}
                variant='body2'
                mobileVariant='body3'
            >
                {text}
            </Text>
        </Box>
    </Box>;
};
