import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box, Skeleton as MuiSekeleton } from '@mui/material';
import { CardWrapper } from 'modules/theme/components/agency/agent/card/partials/cardWrapper/CardWrapper';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

export const Skeleton: FC = () => {
    return <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' height='100%'>
            <Mobile>
                <MuiSekeleton
                    variant='circular'
                    width={80}
                    height={80}
                />

            </Mobile>
            <Desktop>
                <MuiSekeleton
                    variant='circular'
                    width={128}
                    height={128}
                />
            </Desktop>

            <Box textAlign='center' width='100%'>
                <Text
                    center
                    semibold
                    variant='h5'
                    mobileVariant='body2'
                >
                    <MuiSekeleton variant='text' width='40%' sx={{ margin: 'auto' }}/>
                </Text>

                <Text
                    center
                    semibold
                    variant='h5'
                    mobileVariant='body2'
                >
                    <MuiSekeleton variant='text' width='60%' sx={{ margin: 'auto' }}/>
                </Text>
            </Box>
        </Box>
    </CardWrapper>;
};
