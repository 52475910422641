import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { AgentPhoto } from 'modules/theme/components/agency/agent/common/components/AgentPhoto';
import { CardWrapper } from 'modules/theme/components/agency/agent/card/partials/cardWrapper/CardWrapper';
import { AgentDetailLinkWrapper } from 'modules/theme/components/agency/agent/common/components/AgentDetailLinkWrapper';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IAgentCard {
    isPro?: boolean;
    agentName: string;
    detailUrl: string;
    agentPreviewPhotoUrl?: string;
}

export const AgentCard: FC<IAgentCard> = ({ isPro, agentName, detailUrl, agentPreviewPhotoUrl }) => {
    const agentString = agentName || '';

    const alt = [...agentString.split(' '), 'foto', 'maklera', 'realitna', 'kancelaria']
        .filter(value => !!value).join('_');

    return <AgentDetailLinkWrapper
        detailUrl={detailUrl}
    >
        <CardWrapper>
            <Stack gap={{ xs: 1, md: 2 }} height='100%' alignItems='center'>
                <Mobile>
                    <AgentPhoto
                        isPro={isPro}
                        width={80}
                        previewPhotoUrl={agentPreviewPhotoUrl}
                        alt={alt}
                        title={alt}
                    />
                </Mobile>
                <Desktop>
                    <AgentPhoto
                        isPro={isPro}
                        width={128}
                        previewPhotoUrl={agentPreviewPhotoUrl}
                        alt={alt}
                        title={alt}
                    />
                </Desktop>

                <Box maxWidth={{
                    sm: 200,
                    xs: 130,
                }} textAlign='center'>
                    <Text
                        center
                        semibold
                        variant='h5'
                        mobileVariant='body2'
                        lineClamp={3}
                    >
                        {agentString}
                    </Text>
                </Box>
            </Stack>
        </CardWrapper>
    </AgentDetailLinkWrapper>;
};
