import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Address } from 'components/page/realEstate/agency/detail/components/partials/headline/common/Address';
import { IAddress } from 'modules/realEstate/common/interfaces/IAddress';
import { getLocationString } from 'modules/theme/components/agency/agency/listingCard/helpers/getLocationString';

interface IProps {
    agencyName: string;
    agencyAddress: IAddress;
}
export const AgencyAddressMobile: FC<IProps> = ({
    agencyName,
    agencyAddress
}) => {
    const location = getLocationString(agencyAddress?.city, agencyAddress?.street, agencyAddress?.region, agencyAddress?.streetNumber);
    return <>
        <Text
            variant='h6'
            component='h1'
            semibold
            mb={0.5}
        >
            {agencyName}
        </Text>
        {location && (
            <Address
                location={location}
                variant='body3'
            />
        )}
    </>;
};
