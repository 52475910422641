import { FC, ReactNode } from 'react';
import { Stack, useTheme } from '@mui/material';

interface ICardWrapper {
    children: ReactNode;
}

export const CardWrapper: FC<ICardWrapper> = ({ children }) => {
    const { palette } = useTheme();

    return (
        <Stack
            direction='column'
            gap={1}
            borderRadius={2}
            width='100%'
            padding={{ xs: 2, md: 3 }}
            height={{ xs: 190, md: 205 }}
            border={`1px solid ${palette.labelTertiary.main}`}
            boxShadow={'0px 2px 4px ' + palette.labelQuarternary.main}
            data-test-id='cardWrapper'
        >
            {children}
        </Stack>
    );
}; 
