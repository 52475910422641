import { EUnitPriceName } from 'modules/format/enums/EUnitPriceName';

export const formatPrice = (
    priceValue: number,
    unitPriceName?: EUnitPriceName,
    maxDigits?: number
): string => {
    const formatter = new Intl.NumberFormat('sk-SK', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: maxDigits
    });
    const unitPriceSubtitle = unitPriceName ? unitPriceName : '';

    return formatter.format(priceValue) + unitPriceSubtitle;
};